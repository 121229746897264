<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="stayList"
      item-key="id"
      class="table-rounded"
      :search="search"
      :filters="filtreTable"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des Sejours</v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem">
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="filtreTable"
              dense
              :items="listStatus"
              label="Filtré par status"
              outlined
              :item-value="'code'"
              :item-text="'name'"
              @change="filterStay"
            ></v-select>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createStay = true"
            >
              Nouveau Séjour
            </v-btn>
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-if="stayList.length > 0"
                @click="exportToExcel"
            >
              Exporter au format Excel
            </v-btn>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un séjour"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.stays_status="{item}">
        <v-chip
            small
            :color="statusColor[status[item.stays_status]]"
            class="font-weight-medium"
        >
          {{ status[item.stays_status] }}
        </v-chip>&emsp;
      </template>
      <template
        #expanded-item="
          { headers, item }"
      >
        <td
          :colspan="headers.length"
          style="width: 100%"
        >
          Details du Séjour Numéro: {{ item.id }}<br />
          Montant restant a payer : <strong>{{ item.stays_remaining_amount }} FCFA </strong>&emsp;
          Soldé : <strong>{{ item.stays_pay_off ? 'oui' : 'non' }}</strong>&emsp;
          Occupants: <strong>{{ item.stays_number_of_occupants }}</strong>&emsp;
          Reduction: <strong>{{ item.stays_reduce }} FCFA</strong>
          Enregistré avec le compte: <strong>{{ item.user.email }}</strong><br />&emsp;
          Status :  <v-chip
            small
            :color="statusColor[status[item.stays_status]]"
            class="font-weight-medium"
          >
            {{ status[item.stays_status] }}
          </v-chip>&emsp;
          Date entrée:<strong>{{item.stays_enter_date}}</strong>&emsp;
          Date de sortis:<strong>{{item.stays_leaving_date}}</strong>&emsp;
          Nombre de Nuitée:<strong>{{item.stays_number_of_days}}</strong><br>
          Payement effectuer: <br>
          <div v-for="paiment in item.stay_payments"  :key="paiment.id">
            <span>Date de paiement : <strong>{{paiment.pay_stay_date}}</strong></span>&emsp;
            <span>Montant du paiement: <strong>{{paiment.pay_stay_amount}} FCFA</strong></span>
          </div><br/>
          Payement total effectuer: <strong>{{totalPay(item.stay_payments)}} FCFA</strong>
          <div v-show="checkOption(item)" style="display: flex; justify-content: space-between; align-items: start; padding: 1rem">
            <v-btn
              color="primary"
              small
              @click="modified_stay(item)"
            >
              Modifier
            </v-btn>
            <v-btn
              color="error"
              small
              @click="cancelStay(item)"
            >
              Annuler
            </v-btn>
            <v-btn
              color="error"
              small
              @click="shortenStay(item)"
            >
              Écouter
            </v-btn>
            <v-btn
              color="error"
              small
              @click="updateStayCustomer(item)"
            >
              Changer de client
            </v-btn>
            <v-btn
              color="success"
              small
              @click="closeStay(item)"
            >
              Clôturer
            </v-btn>
            <v-btn
              color="success"
              small
              @click="updateStayLeavingDate(item)"
            >
              Reconduire
            </v-btn>
            <v-btn
              color="error"
              small
              @click="updateStayRoom(item)"
            >
              Reloger
            </v-btn>
          </div>  <v-btn
            color="primary"
            small
            @click="showInvoice(item)"
        >
          Imprimer la facture
        </v-btn>
        </td>
      </template>
    </v-data-table>
    <!--    dialog to modify stay begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                <mdi-close-circle />
                Fermer
              </v-btn>&emsp;
              <v-toolbar-title class="text--white">
                Modification du sejour
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  color="primary"
                  class="mt-4"
                  @click="dialog = false"
                >
                  Sauvegarder
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="Legal first name*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="Legal middle name"
                    hint="example of helper text only on focus"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="Legal last name*"
                    hint="example of persistent helper text"
                    persistent-hint
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    :items="['0-17', '18-29', '30-54', '54+']"
                    label="Age*"
                    required
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    :items="[
                      'Skiing',
                      'Ice hockey',
                      'Soccer',
                      'Basketball',
                      'Hockey',
                      'Reading',
                      'Writing',
                      'Coding',
                      'Basejump',
                    ]"
                    label="Interests"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to modify stay end-->
    <!--    dialog to create stay begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="createStay"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="createStay = false"
              >
                <mdi-close-circle />
                Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau sejour
              </v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >
                    Infos relative au client
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                    Infos relative au séjour
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                    Resumé
                  </v-stepper-step>
                </v-stepper-header>
                <v-form class="multi-col-validation">
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row style="margin-top: 1%">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-autocomplete
                            v-model="stay.customer_id"
                            dense
                            :items="client_list"
                            label="Client"
                            :item-value="'id'"
                            outlined
                            deletable-chips
                            filled
                            :item-text="
                              item => item.cust_lastname + ' ' + item.cust_firstname + '(' + item.cust_contact + ')'
                            "
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            outlined
                            @click="createClient = true"
                          >
                            NOUVEAU CLIENT
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_enter_date"
                            dense
                            type="date"
                            hide-details
                            label="Date d'entrée"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_leaving_date"
                            dense
                            type="date"
                            hide-details
                            label="Date de sortir"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_number_of_occupants"
                            dense
                            type="number"
                            hide-details
                            label="Nombre d'occupants"
                            outlined
                            placeholder="occupants de la chambre"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_number_of_children"
                            dense
                            type="number"
                            hide-details
                            label="Nombre d'enfants"
                            outlined
                            placeholder="nombre de mineur"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e1 = 1"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="e1 = 2"
                          >
                            Continuer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-row style="margin-top: 1%">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_date"
                            dense
                            type="date"
                            hide-details
                            label="Date d'enregistrement"
                            outlined
                            placeholder="Date de creation"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="stay.stay_type_id"
                            dense
                            :items="stay_type_list"
                            name="stay_type_list"
                            :item-text="item => item.stay_typ_name"
                            :item-value="'id'"
                            label="Type de sejour"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="stay.room_id"
                            dense
                            :items="room_list"
                            name="room_list"
                            :item-text="item => item.room_name +' ('+item.room_type.room_type_name+')'"
                            :item-value="'id'"
                            label="Chambre choisir"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            dense
                            type="text"
                            hide-details
                            label="Prix de la chambre"
                            outlined
                            disabled
                            :value="room_list.filter(c=>c.id===stay.room_id)[0]?room_list.filter(c=>c.id===stay.room_id)[0].room_total_price - stay.stays_reduce:''"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_reduce"
                            dense
                            type="number"
                            label="Reduction"
                            outlined
                            placeholder="Montant de la reduction"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <nav-menu-section-title title="Payement du Sejour"></nav-menu-section-title>
                      <v-row style="margin-top: 1%">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.pay_stay_date"
                            dense
                            type="date"
                            hide-details
                            label="Date du paiement"
                            outlined
                            placeholder="Date de paiement"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.pay_stay_ref"
                            dense
                            hide-details
                            label="Reference du paiement"
                            outlined
                            placeholder="Reference"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.pay_stay_amount"
                            dense
                            type="number"
                            hide-details
                            label="Montant du paiement"
                            outlined
                            placeholder="Montant"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="stay.stays_vat"
                            dense
                            type="number"
                            hide-details
                            label="TVA(en %)"
                            outlined
                            placeholder="exemple:0,5"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="stay.payment_type_id"
                            dense
                            :items="payment_type_list"
                            name="payment_type_list"
                            :item-text="item => item.pay_typ_name"
                            :item-value="'id'"
                            label="Methode de paiement"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e1 = 1"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="intResume"
                          >
                            Continuer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      RESUME
                      <v-row align-content-md="center">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          Details du S&eacute;jour:  <br>
                          <p><span>Date D'entrer</span> :<strong>{{ stay.stays_enter_date }}</strong></p>
                          <p><span>Date de sortir</span> :<strong>{{ stay.stays_leaving_date }}</strong></p>
                          <p>
                            <span>Type de sejour</span> :<strong>
                              {{ stay_resume.stayType }}
                            </strong>
                          </p>
                          <p>
                            <span>Nom de la chambre</span> :<strong>
                              {{ stay_resume.roomName }}
                            </strong>
                          </p>
                          <p>
                            <span>Type de la chambre</span> :<strong>
                              {{ stay_resume.roomType }}
                            </strong>
                          </p>
                          <p>
                            <span>Nom du client</span> :<strong>
                              {{ stay_resume.customerName }}
                            </strong>
                          </p>
                          <p>
                            <span>Numero du client</span> :<strong>
                              {{ stay_resume.customerPhone }}
                            </strong>
                          </p>
                          <p><span>Nombre d'occupants</span> :<strong>{{ stay.stays_number_of_occupants }}</strong></p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          Details du S&eacute;jour:  <br>
                          <p>
                            <span>Nombre de nuite</span> :<strong>
                              {{ stay_resume.stayNumber }}
                            </strong>
                          </p>
                          <p>
                            <span>Prix de la chambre</span> :<strong>
                              {{ stay_resume.roomPrice }}
                            </strong>
                          </p>
                          <p><span>Tva</span> :<strong>{{ stay.stays_vat }}</strong></p>
                          <p><span>Montant total &aacute; payer</span> :<strong>{{ stay_resume.stayTotalAmount }}</strong></p>
                          <p><span>Montant de la reduction</span> :<strong>{{ stay.stays_reduce }} FCFA</strong></p>
                          <p><span>Montant total après reduction</span> :<strong>{{ stay_resume.amount_reduce }}</strong></p>
                          <p><span>Montant Pay&eacute;</span> :<strong>{{ stay.pay_stay_amount }}</strong></p>
                          <p><span>Montant restant : </span> :<strong>{{ stay_resume.stayAmountRemain }}</strong></p>
                          <p><span>Sold&eacute;</span> :<strong>{{ stay_resume.staySold }}</strong></p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e1 = 2"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="newStay"
                          >
                            Valider
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-form>
              </v-stepper>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to create stay end-->
    <!--    dialog to create client begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="createClient"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="createClient = false"
              >
                <mdi-close-circle />
                Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau Client
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <v-stepper v-model="e2">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="e2 > 1"
                    step="1"
                  >
                    Informations personnel
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e2 > 2"
                    step="2"
                  >
                    Informations supplementaire
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                    Resumé
                  </v-stepper-step>
                </v-stepper-header>
                <v-form class="multi-col-validation">
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row style="margin-top: 1%">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="customer.cust_civility"
                            dense
                            :items="['M.', 'Mme', 'Mle']"
                            label="Civilite"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_lastname"
                            dense
                            required
                            hide-details
                            label="Nom"
                            outlined
                            placeholder="Nom"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_firstname"
                            dense
                            hide-details
                            required
                            label="Prénom"
                            outlined
                            placeholder="Prénom"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_birthday"
                            dense
                            type="date"
                            hide-details
                            label="Date d'anniversaire"
                            outlined
                            placeholder="date de naissance"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_birth_place"
                            dense
                            hide-details
                            label="Lieu de Naissance"
                            outlined
                            placeholder="lieu de Naissance"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="customer.cust_type_id"
                            dense
                            :items="CustomerIdType"
                            label="Type de pièce d'identité"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_reference_id"
                            dense
                            hide-details
                            label="Reference de la pièce"
                            outlined
                            placeholder="Reference"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_id_expiration_date"
                            type="date"
                            dense
                            hide-details
                            label="Date d'expiration de la piece"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_contact"
                            dense
                            hide-details
                            label="Contact"
                            outlined
                            placeholder="numero de telephone"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_place_residence"
                            dense
                            hide-details
                            label="Lieu de residence"
                            outlined
                            placeholder="Domicile"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e2 = 1"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="e2 = 2"
                          >
                            Continuer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_mother_name"
                            dense
                            hide-details
                            label="Non de la mere"
                            outlined
                            placeholder="Non complet"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_father_name"
                            dense
                            hide-details
                            label="Non du pere"
                            outlined
                            placeholder="Nom complet"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_job"
                            dense
                            hide-details
                            label="Profession"
                            outlined
                            placeholder="metier"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_pseudonym"
                            dense
                            hide-details
                            label="Pseudonyme"
                            outlined
                            placeholder="nom de carresse"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="customer.cust_nationality"
                            dense
                            name="room"
                            :item-text="'name'"
                            :item-value="'id'"
                            :items="[
                              'Ivoirien',
                              'Togolais',
                              'Beninois',
                              'Ghanien',
                              'Burkinabe',
                              'Guineen',
                              'Negerian',
                              'Malien',
                            ]"
                            label="Nationalite"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <!--                :value="apartments_list.id"-->
                          <v-select
                            v-model="customer.cust_city"
                            dense
                            :items="['Abidjan', 'Yamoussoukro', 'Daloa', 'Accra', 'Bamako', 'Ouagadougou']"
                            name="apartment"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Ville"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="customer.cust_country"
                            dense
                            :items="['Cote d\'ivoire', 'Mali', 'Benin', 'France', 'Burkina Faso', 'Canada']"
                            name="apartment"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Pays"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="customer.cust_date_of_entering_ivory_coast"
                            type="date"
                            dense
                            hide-details
                            label="Date d'entre en cote d'ivoire"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e2 = 1"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="e2 = 3"
                          >
                            Continuer
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-toolbar-title>Resume</v-toolbar-title>
                          {{ customer }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            color="primary"
                            @click="e2 = 2"
                          >
                            Precedent
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-btn
                            style="margin-left: 1rem; margin-right: 1rem"
                            color="primary"
                            @click="newCustormer"
                          >
                            Valider
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-form>
              </v-stepper>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to create client end-->
    <!--    dialog to update stay infos begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="active_stay_u_infos"
          max-width="500px"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title color="white">
                Modifier les infos de ce sejour
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <v-form class="multi-col-validation">
                <v-row style="margin-top: 1%">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_info.stays_number_of_occupants "
                      type="number"
                      dense
                      hide-details
                      label="Nombre d'occupants"
                      outlined
                      placeholder="Occupants"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_info.stays_number_of_children "
                      type="number"
                      dense
                      hide-details
                      label="Nombre d'enfants"
                      outlined
                      placeholder="Enfants"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      color="primary"
                      @click="active_stay_u_infos = false"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      style="margin-left: 1rem; margin-right: 1rem"
                      color="primary"
                      @click="updateStay()"
                    >
                      Modifier
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to update stay infos end-->
    <!--    dialog to update stay leaving date begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="active_stay_u_leaving_date"
          max-width="500px"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title color="white">
                Modifier la date de sortir de ce séjour
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <v-form class="multi-col-validation">
                <v-row style="margin-top: 1%">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_leaving_date.old_date"
                      dense
                      hide-details
                      label="Ancienne Date de sortir"
                      outlined
                      placeholder="Date precedente"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_leaving_date.new_date"
                      type="date"
                      dense
                      hide-details
                      label="Nouvelle date de sortir"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      color="primary"
                      @click="active_stay_u_leaving_date = false"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      style="margin-left: 1rem; margin-right: 1rem"
                      color="primary"
                      @click="ChangeStayLeavingDate"
                    >
                      Modifier
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to update stay leaving date end-->
    <!--    dialog to update stay customer begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="active_stay_u_customer"
          max-width="500px"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title color="white">
                Changer le client lier a ce sejour
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <v-form class="multi-col-validation">
                <v-row style="margin-top: 1%">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_customer.old_customer"
                      dense
                      hide-details
                      label="Ancien client"
                      outlined
                      placeholder="client precedent"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="update_stay_customer.customer_id"
                      dense
                      :items="client_list"
                      label="Nouveau Client"
                      outlined
                      :item-value="'id'"
                      :item-text="item=> item.cust_lastname +' '+ item.cust_firstname+ ':' + item.cust_contact"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      color="primary"
                      @click="active_stay_u_customer = false"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      style="margin-left: 1rem; margin-right: 1rem"
                      color="primary"
                      @click="changeStayCustomer"
                    >
                      Modifier
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to update stay customer end-->
    <!--    dialog to update stay room begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="active_stay_u_room"
          max-width="500px"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title color="white">
                Reloger le client de ce sejour
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <v-form class="multi-col-validation">
                <v-row style="margin-top: 1%">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="update_stay_room.old_room"
                      dense
                      hide-details
                      label="Ancienne chambre"
                      outlined
                      placeholder="chambre precedente"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="update_stay_room.new_room"
                      dense
                      :items="room_list"
                      label="Nouvelle Chambre"
                      outlined
                      :item-value="'id'"
                      :item-text="'room_name'"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      color="primary"
                      @click="active_stay_u_room = false"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      style="margin-left: 1rem; margin-right: 1rem"
                      color="primary"
                      @click="changeStayRoom"
                    >
                      Modifier
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to update stay room end-->
    <!--  <v-card>-->
    <!--  </v-card>-->
  </div>
</template>

<script>
import { mdiDotsVertical, mdiSquareEditOutline, mdiCloseCircle } from '@mdi/js'
// eslint-disable-next-line import/extensions,import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import NavMenuSectionTitle from '../../layouts/components/vertical-nav-menu/components/NavMenuSectionTitle.vue';
import Stay from './StayModel';
import StayFullModel from './StayFullModel';
import Customer from '../customer/CustomerModel';
import StayPaymentModel from './StayPaymentModel';
import  CustomerIdType from '../../assets/CustomerIdType'
import {json2excel, excel2json} from 'js2excel';

import {VRow,VCol,VBtn, VDataTable,VSpacer,VToolbar, VStepperContent,VStepper,VStepperItems,
  VTextField,
  VStepperHeader,
    VForm,VContainer,
  VCard,VDialog,
  VStepperStep,
  VSelect,
    VDivider,
    VMain,VApp,VContent,
  VAutocomplete,
  VToolbarTitle,} from 'vuetify'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Stay',
  components: {
    mdiCloseCircle,
    VStepperContent,VStepper,
    VStepperItems,
    VStepperHeader,
    NavMenuSectionTitle,
    VForm,VContainer,
    VCard,VDialog,
    VTextField,
    VMain,VApp,VContent,
    VSelect,
    VDivider,
    VStepperStep,
    VAutocomplete,
    VToolbarTitle,
    VRow,
    VCol,
    VBtn,
    VDataTable,
    VSpacer,
    VToolbar
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      En_cours: 'primary',
      Cloturé: 'success',
      Annulé: 'warning',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'REFERENCE', value: 'id' },
        { text: 'TYPE DE SÉJOUR', value: 'stay_type.stay_typ_name' },
        { text: 'CHAMBRE CONCERNE', value: 'room.room_name' },
        { text: 'CLIENT', value: 'customer.fullname' },
        { text: "DATE D'ENREGISTREMENT", value: 'stays_date' },
        { text: 'PRIX DU SÉJOUR', value: 'stays_total_price_to_pay_fcfa' },
        { text: 'STATUS', value: 'stays_status' },
      ],
      usreList: [],
      status: {
        400: 'En_cours',
        200: 'Cloturé',
        500: 'Annulé',
        550: 'Ecourter',
        5: 'Applied',
      },
      statusColor,
      CustomerIdType,
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      search: '',
      calories: '',
      filtreTable: 400,
      listStatus: [
        { name: 'en cours', code: 400 },
        { name: 'terminer', code: 200 },
        { name: 'annuler', code: 500 },
        { name: 'ecouter', code: 550 },
        { name: 'tous les séjours', code: 0 },
      ],
      stay_type_list: [],
      room_list: [],
      stay_temp: {},
      stay_resume: Stay,
      stayList: [],
      stayBackup: [],
      expanded: [],
      singleExpand: false,
      dialog: false,
      createStay: false,
      createClient: false,
      active_stay_u_infos: false,
      active_stay_u_leaving_date: false,
      active_stay_u_customer: false,
      active_stay_u_room: false,
      e1: 1,
      e2: 1,
      stay: StayFullModel,
      client_list: [],
      payment_type_list: [],
      stay_payement: StayPaymentModel,
      customer: Customer,
      update_stay_info: {
        stays_number_of_occupants: 0,
        stays_number_of_children: 0,
      },
      update_stay_leaving_date: {
        old_date: '',
        new_date: '',
      },
      update_stay_customer: {
        old_customer: '',
        customer_id: 0,
      },
      update_stay_room: {
        old_room: '',
        new_room: 0,
      },
    }
  },
  computed: {},
  mounted() {
    this.loadCustomer()
    this.loadStays()
    this.loadPaymentType()
    this.loadRoom()
    this.loadStayTypes()
  },
  methods: {
    filterStay() {
      // eslint-disable-next-line radix
      this.stayList = this.stayBackup
      // eslint-disable-next-line radix
      this.stayList = this.stayList.filter(st => (parseInt(st.stays_status) === this.filtreTable)).length
      // eslint-disable-next-line radix
        ? this.stayList.filter(st => (parseInt(st.stays_status) === this.filtreTable)) : this.stayBackup
    },
    modified_stay(stay) {
      this.active_stay_u_infos = true
      this.update_stay_info.stays_number_of_occupants = stay.stays_number_of_occupants
      this.update_stay_info.stays_number_of_children = stay.stays_number_of_children
      this.stay_temp = stay
    },
    newStay() {
      this.$axios.post(this.$endpoint.CreateStay, this.stay).then(rp => {
        this.createStay = false
        this.loadStays()
        window.open(rp.data.invoice, '_blank')
      })
    },
    intResume() {
      const tp_stay = this.stay_type_list.find(tp => tp.id === this.stay.stay_type_id)
      if (tp_stay !== undefined) {
        this.stay_resume.stayType = tp_stay.stay_typ_name
      }
      const room = this.room_list.find(rm => rm.id === this.stay.room_id)
      const date1 = new Date(this.stay.stays_enter_date)
      const date2 = new Date(this.stay.stays_leaving_date)
      // eslint-disable-next-line camelcase
      const Difference_In_Time = date2.getTime() - date1.getTime()
      // eslint-disable-next-line camelcase
      this.stay_resume.stayNumber = Difference_In_Time / (1000 * 3600 * 24)
      if (room !== undefined) {
        this.stay_resume.roomName = room.room_name
        this.stay_resume.roomType = room.room_type.room_type_name
        this.stay_resume.roomPrice = room.room_total_price
        this.stay_resume.stayTotalAmount = (room.room_total_price * this.stay_resume.stayNumber) + ((room.room_total_price * this.stay_resume.stayNumber) * (this.stay.stays_vat / 100))
        this.stay_resume.amount_reduce = ((room.room_total_price - this.stay.stays_reduce) * this.stay_resume.stayNumber) + ((room.room_total_price * this.stay_resume.stayNumber) * (this.stay.stays_vat / 100))
        this.stay_resume.stayAmountRemain = this.stay_resume.amount_reduce - this.stay.pay_stay_amount
        if (this.stay_resume.stayAmountRemain === 0) {
          this.stay_resume.staySold = 'Soldé'
        } else {
          this.stay_resume.staySold = 'Non-soldé'
        }
        const customer = this.client_list.find(ct => ct.id === this.stay.customer_id)
        if (customer !== undefined) {
          this.stay_resume.customerName = `${customer.cust_lastname} ${customer.cust_firstname}`
          this.stay_resume.customerPhone = customer.cust_contact
        }
      }
      this.e1 = 3
    },
    newCustormer() {
      if (this.customer.cust_id_expiration_date === '') {
        this.customer.cust_id_expiration_date = null
      }
      this.$axios.post(this.$endpoint.CreateCustomer, this.customer).then(rp => {
        this.createClient=false
        this.loadCustomer()
      })
    },
    updateStayLeavingDate(stay) {
      this.active_stay_u_leaving_date = true
      this.update_stay_leaving_date.old_date = stay.stays_leaving_date
      this.stay_temp = stay
    },
    updateStayCustomer(stay) {
      this.active_stay_u_customer = true
      this.update_stay_customer.old_customer = stay.customer.fullname
      this.stay_temp = stay
    },
    updateStayRoom(stay) {
      this.active_stay_u_room = true
      this.stay_temp = stay
      this.update_stay_room.old_room = stay.room.room_name
    },
    changeStayRoom() {
      this.$axios.patch(this.$endpoint.ChangeStayRoom(this.stay_temp.id), {room_id: this.update_stay_room.new_room}).then(rp => {
        this.active_stay_u_room = false
        this.loadStays()
      })
    },
    changeStayCustomer() {
      this.$axios.patch(this.$endpoint.ChangeStayCustomer(this.stay_temp.id), { customer_id: this.update_stay_customer.customer_id }).then(rp => {
        console.log(rp)
        this.loadStays()
        this.active_stay_u_customer = false
      })
    },
    totalPay(payments) {
      let total = 0;
      for (let e=0; e< payments.length; e++){
        total = total + payments[e].pay_stay_amount
      }
      return total
    },
    ChangeStayLeavingDate() {
      this.$axios.patch(this.$endpoint.ChangeStayLeavingDate(this.stay_temp.id), {stays_leaving_date: this.update_stay_leaving_date.new_date}).then(rp => {
        this.loadStays()
        this.active_stay_u_leaving_date = false
      })
    },
    showInvoice(stay) {
      this.$axios.patch(this.$endpoint.ShowStayInvoice, stay).then(rp => {
        this.loadStays()
        window.open(rp.data.invoice, '_blank')
      })
    },
    cancelStay(stay) {
      this.$axios.patch(this.$endpoint.CancelStay(stay.id), stay).then(rp => {
        this.loadStays()
      })
    },
    shortenStay(stay) {
      this.$axios.patch(this.$endpoint.ShortenStay(stay.id), stay).then(rp => {
        this.loadStays()
        window.open(rp.data.invoice, '_blank')
      })
    },
    closeStay(stay) {
      this.$axios.patch(this.$endpoint.CloseStay(stay.id)).then(rp => {
        this.loadStays()
      })
    },
    updateStay() {
      this.$axios.patch(this.$endpoint.UpdateStay(this.stay_temp.id), this.update_stay_info).then(rp => {
        this.loadStays()
        this.active_stay_u_infos = false
      })
    },
    loadStays() {
      this.$axios.get(this.$endpoint.LoadStay).then(rp => {
        this.stayList = rp.data
        this.stayBackup = rp.data
      })
    },
    loadStayTypes() {
      this.$axios.get(this.$endpoint.LoadStayType).then(rp => {
        this.stay_type_list = rp.data
      })
    },
    loadPaymentType() {
      this.$axios.get(this.$endpoint.LoadPaymentType).then(rp => {
        this.payment_type_list = rp.data
      })
    },
    exportToExcel(){
      try {
        const excelData=[];
        this.stayList.map(item => {
          excelData.push({
            Date_enregistrement:item.stays_date,
            Type_de_sejour: item.stay_type.stay_typ_name,
            Appartement_Concerner: item.room.room_name,
            Client: item.customer.fullname,
            Montant_total_du_sejour:item.stays_total_price_to_pay_fcfa,
            Status_du_sejour:item.stays_status,
            Montant_restant_a_payer:item.stays_remaining_amount,
            Nombre_occupants:item.stays_number_of_occupants,
            Sejour_enregistrer_par:item.user.email,
            Date_entrer:item.stays_enter_date,
            Date_de_sortir:item.stays_leaving_date,
            Nombre_de_nuitee:item.stays_number_of_days,
          })
        })
        json2excel({
          data:  excelData,
          name: 'list_sejours',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        console.error('export error: ', e);
      }
    },
    loadCustomer() {
      this.$axios.get(this.$endpoint.LoadCustomer).then(rp => {
        this.client_list = rp.data
      })
    },
    checkOption(item) {
      return item.stays_status == 400
    },
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoom).then(rp => {
        this.room_list = rp.data
        this.room_list = this.room_list.filter(room => room.room_status === 400)
      })
    },

  },
}
</script>

<style scoped>
</style>
