const StayFullModel = {
    stays_status: 400,
    stays_date: '',
    stays_enter_date: '',
    stays_leaving_date: '',
    stays_number_of_days: 0,
    stays_total_price_to_pay: 0.0,
    stays_reduce: 0.0,
    stays_vat: 0.0,
    stays_remaining_amount: 0.0,
    stays_pay_off: 0.0,
    stays_number_of_occupants: 0,
    stays_number_of_children: 0,
    room_id: 0,
    customer_id: 0,
    stay_type_id: 0,
    user_id: JSON.parse(localStorage.getItem('currentUser')).id,
    pay_stay_date: '',
    pay_stay_ref: '',
    pay_stay_amount: 0.0,
    stay_id: 0,
    payment_type_id: 0,
};
export default StayFullModel;