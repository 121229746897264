const Stay = {
    roomName: '',
    roomType: '',
    amount_reduce: 0,
    roomPrice: 0,
    customerName: '',
    customerPhone: '',
    stayNumber: '',
    stayType: '',
    stayTotalAmount: 0,
    stayAmountRemain: 0,
    staySold: '',
};
export default Stay;