const  Customer = {
        cust_sexe: '',
        cust_lastname: '',
        cust_firstname: '',
        cust_contact: '',
        cust_birthday: '',
        cust_birth_place: '',
        cust_father_name: '',
        cust_mother_name: '',
        cust_pseudonym: '',
        cust_country: '',
        cust_city: '',
        cust_nationality: '',
        cust_type_id: '',
        cust_reference_id: '',
        cust_email: '',
        cust_id_expiration_date: '',
        cust_job: '',
        cust_place_residence: '',
        cust_date_of_entering_ivory_coast: '',
};
export default Customer;